import React from 'react';
import styled from 'styled-components';
import { colors } from '../utils/consts';

export const InlineLink = styled.a`
  display: inline-block;
  position: relative;
  z-index: 0;
  &::after {
    content: '';
    width: 0;
    display: inline-block;
    position: absolute;
    background: ${colors.primaryLight};
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: height ease-in-out 100ms;
    transform: rotate(-3deg);
    width: 100%;
    height: 10px;
  }
  &:hover {
    z-index: 0;
    &::after {
      background: ${colors.primaryLight};
      opacity: 0.8;
      width: 100%;
      height: 14px;
    }
  }
`;

import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import classNames from 'classnames';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import navbarStyles from '../styles/Navbar.module.scss';
import { Label } from './Label';

const SmallLabel = styled(Label)`
  font-size: 8px;
  padding: 0 2px;
  display: inline;
  position: absolute;
  white-space: nowrap;
  bottom: 0;
  left: 50%;
  transform: rotate(-3deg) translateX(-50%);
`;

function Navbar() {
  const data = useStaticQuery(graphql`
    query NavbarQuery {
      logo: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 100, maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);
  return (
    <nav className={navbarStyles.navbar}>
      <div
        className={classNames(navbarStyles.navitemLeft, navbarStyles.navitem)}
      >
        <Link
          style={{
            display: 'block',
            width: 60,
          }}
          to="/"
        >
          <GatsbyImage
            imgStyle={{
              borderRadius: '50%',
            }}
            fluid={data.logo.childImageSharp.fluid}
          />
          <SmallLabel>Matthias Wehnert</SmallLabel>
        </Link>
      </div>
      <input id={navbarStyles.toggleMobileMenu} type="checkbox" />
      <label htmlFor={navbarStyles.toggleMobileMenu}>
        <span className={navbarStyles.showNavIcon}>👇</span>
        <span className={navbarStyles.hideNavIcon}>☝️</span>
        <span className={navbarStyles.toggleNavText}>Menu</span>
      </label>
      <ul>
        {data.site.siteMetadata.menuLinks.map(menuEntry => (
          <li className={navbarStyles.navitem}>
            <Link to={menuEntry.link} activeClassName={navbarStyles.active}>
              {menuEntry.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import Footer from './Footer';
import Navbar from './Navbar';
import layoutStyles from '../styles/Layout.module.scss';

const MainInner = styled.div`
  padding: 0 40px;
  @media (max-width: 760px) {
    padding: 0;
  }
`;

function Layout({ children, hero }) {
  const hasHero = hero !== undefined;

  return (
    <React.Fragment>
      <header>
        <Navbar />
      </header>
      <main className={classNames(layoutStyles.main)}>
        {hasHero ? hero() : ''}
        <div className={layoutStyles.bottomHelper} />
        <div className={layoutStyles.mainWrapper}>
          <MainInner>{children}</MainInner>
        </div>
      </main>
      <aside />
      <Footer />
    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  hero: PropTypes.node,
};

export default Layout;

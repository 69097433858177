import React from 'react';
import styled from 'styled-components';
import { colors } from '../utils/consts';

export const Label = styled.span`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: ${({ dark }) =>
    dark ? colors.primaryDark : colors.primaryLight};
  padding: 0 5px;
  color: ${({ dark }) => (dark ? colors.light : colors.dark)};
  z-index: 999;
  font-weight: bold;
  transform: ${({ horizontal }) => (!horizontal ? 'rotate(-3deg)' : '')};
  display: inline-block;
  * {
    margin: 0;
  }
`;

import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { colors } from '../utils/consts';

const TopTriagle = styled.div`
  height: 60px;
  background-image: linear-gradient(
    to top right,
    ${colors.accent} 50%,
    ${colors.light} 50%
  );
`;

const FooterContent = styled.section`
  background: ${colors.accent};
  color: ${colors.dark};
  text-align: center;
  height: 90px;
  /* height: 50px; */
`;

const FooterContainer = styled.footer`
  height: 150px;
`;

const FooterLink = styled(Link)`
  &:hover,
  &:focus {
    color: ${colors.dark};
  }
`;

function Footer() {
  //   const data = useStaticQuery(graphql`
  //     query FooterQuery {
  //       logo: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 100, maxHeight: 100) {
  //             ...GatsbyImageSharpFluid
  //           }
  //         }
  //       }
  //       site {
  //         siteMetadata {
  //           title
  //           menuLinks {
  //             name
  //             link
  //           }
  //         }
  //       }
  //     }
  //   `);
  return (
    <FooterContainer>
      <TopTriagle />
      <FooterContent>
        © {new Date().getFullYear()}, Built with ❤️ in Bonn <br />
        <FooterLink to="/legal">Impressum</FooterLink>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;

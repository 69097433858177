import React from 'react';
import styled from 'styled-components';

import { Label } from './Label';

const TitleWrapper = styled.section`
  margin-top: -40px;
  margin-bottom: 40px;
`;

export default function PageTitle({ title, subtitle, meta }) {
  return (
    <TitleWrapper>
      {meta && (
        <div>
          <Label>{meta}</Label>
        </div>
      )}
      <div>
        <Label dark horizontal>
          <h1>{title || 'Page with no title! Deal with it.'}</h1>
        </Label>
      </div>
      {subtitle && (
        <div>
          <Label>
            <h2>{subtitle}</h2>
          </Label>
        </div>
      )}
    </TitleWrapper>
  );
}
